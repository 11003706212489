
/* ------------------------------------------------------------------------------------------------------
//   Projektspeicher Theme 2024
// ----------------------------------------------------------------------------------------------------*/

:root,
:host {
  --ignt-theme-name: "HSK";

  --ignt-color-primary: #000;
  --ignt-color-secondary: #000;
  --ignt-color-text-default: var(--ignt-color-secondary-500);
  --ignt-basic-color: #fff;

  --ignt-surface-light: color-mix(in srgb, var(--ignt-color-neutral-0), var(--ignt-mix-color-up) 3%);
  --ignt-surface-dark: color-mix(in srgb, var(--ignt-color-neutral-0), var(--ignt-mix-color-up) 7%);

  --ignt-color-hover-default: #f7f7f7;

  /* Alert colors */
  --ignt-color-success: #419839;
  --ignt-color-warning: #ff8c00;
  --ignt-color-danger: color-mix(in oklch, var(--ignt-color-primary), darkred 80%);
  --ignt-color-info: #4785a7;

  /* Buttons, Inputs, Forms and Menus */
  --ignt-color-form-default: var(--ignt-color-neutral-0);

  /* Font sizes */
  --ignt-font-size-3x-small: 0.8rem; /* 10px */
  --ignt-font-size-2x-small: 0.85rem; /* 12px */
  --ignt-font-size-x-small: 0.9rem; /* 13px */
  --ignt-font-size-small: 1rem; /* 14px */
  --ignt-font-size-medium: 1.1rem; /* 15px */
  --ignt-font-size-large: 1.1rem; /* 16px */
  --ignt-font-size-x-large: 1rem; /* 18px */
  --ignt-font-size-2x-large: 1.25rem; /* 20px */
  --ignt-font-size-3x-large: 1.375rem; /* 22px */
  --ignt-font-size-4x-large: 1.5rem; /* 24px */

  --ignt-input-height-large: 2.5rem;

  /* Font Weights */
  --ignt-font-weight-light: 300;
  --ignt-font-weight-normal: 400;
  --ignt-font-weight-bold: 700;

  /* Font Stacks */
  /* Default font stack. */
  --ignt-font-sans: 'Source Sans Pro', 'Open Sans', sans-serif;

  /* Fonts for headlines, blockquotes and alike. */
  --ignt-basic-font-head: 'Vieleck', 'Source Sans Pro', 'Open Sans', sans-serif;
  --ignt-head-font-sans: 'Vieleck', 'Source Sans Pro', 'Open Sans', sans-serif;

  --ignt-page-width-default: 1300px;
  --ignt-bind-padding: var(--sl-spacing-3x-large);
  --ignt-inner-page-width: calc(var(--ignt-page-width-default) - (2 * var(--ignt-bind-padding)));

  --ignt-border-radius-default: 8px;

  --ignt-menu-height: 130px;

  /* Media queries / Supported screen sizes */
  --ignt-break-2x-small: 32em; /* old smart phones or gadgets like smart watches up to: 512px / 16px = 32em */
  --ignt-break-x-small: 54em; /* smart phones up to: 640px / 16px = 40em */
  --ignt-break-small: 80em; /* tablets up to: 1040px / 16px = 65em */
  --ignt-break-medium: 75em; /* screens: 1200px / 16px = 75em */
  --ignt-break-large: 94em; /* big screens up to: 1504px / 16px = 94em */
  --ignt-break-x-large: 105em; /* enough space left and right of the main binder: 1680px / 16px = 105em */
  --ignt-break-2x-large: 114em; /* huge screens: 1824px / 16px = 114em; */
  --ignt-break-3x-large: 225em; /* 4k screens: 3600px / 16px = 225em; */

  --fluid-height-small: 300px;
  --fluid-height-medium: 450px;
  --fluid-height-large: 600px;

  --ignt-outer-space: 20px;
  --ignt-footer-height: 100px;
}
