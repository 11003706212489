@charset "UTF-8";
/* IMAGE */

// avoid overflow by high resolution images
img {
  max-width: 100%;
  height: auto;
  @media print{
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }
}



