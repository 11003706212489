@charset "UTF-8";
/* TOPBAR */

.topbar {
  display: flex;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, .25);
  padding: var(--ignt-outer-space);
  margin-top: -50px;
  transform: skewY(-1deg) translateY(50px);
  transform-origin: left;
  width: 100%;
  align-items: flex-end;
  z-index: 22;
}


