@charset "UTF-8";
/* Wrap */

::selection {
  background: var(--ignt-color-primary);
  color: #fff;
}

html{
  hyphens: auto;
  scroll-behavior: smooth;
}

body{
  background-color: #fff;
}

.headline{
  word-break: break-all;
}

.outerwrap {
    display: flex;
    flex-wrap: wrap;
    min-height: 100%;
  }

  .wrap {
    position: relative;
    min-width: 100%;
    flex: 1 0 100%;

    &.wrap--black{
      background-color: #000;
    }
  }

  .bind {
    max-width: var(--ignt-page-width-default);
    padding: 0 var(--ignt-outer-space);
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;

    &.bind--sidebar{
      flex-direction: row;

      @media (--ignt-mq-max-break-medium){
        flex-direction: column;
      }
    }

  }

  /* Sidebars */
  .content ~ .sidebar {
    margin-left: 0;
    padding: 0 !important;
    flex: auto;
    &:nth-of-type(2) {
      flex: 0 0 20%;
      order: 22;
    }

    @media (--ignt-mq-min-break-small) {
          flex: 0 0 25%;
          padding: @innerSpace 0 @bottomSpace @innerSpace;
    }
    @media (--ignt-mq-min-break-medium) {
      flex: 0 0 25%;
      margin-top: 70px;
      margin-left: 40px !important;
    }
  }

  .content ~ .sidebar--left {
    @media (--ignt-mq-min-break-medium) { padding: @innerSpace @innerSpace*3 @bottomSpace 0; }
  }

  .content ~ .sidebar--marginal {
    font-size: .8em;
    flex: 0 0 15%;
    min-width: 200px;
  }

  /* Content Containers */
  .content {
    display: flex;
    flex-direction: column;
    flex: 2 0;
    min-width: 0;
    max-width: 100%;
  }

  /* Container to separate chunks of content in the middle of the page. */
  .section {
    min-width: 0;
    padding-top: @innerSpace;
    padding-bottom: @bottomSpace;
    display: flex;
    flex-direction: column;
  }

  .social-media-wrap{
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 40vh;
    z-index: 100;
  }

  .social-media-item{
    width: 45px;
    height: 45px;
    background-color: #000;
    color: #fff;
    transition: all ease .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    box-sizing: content-box;

    .icon{
      color: inherit;
      width: 75%;
      height: 75%;
      transition: all ease .3s !important;

      *{
       transition: none !important;
      }
    }

    &:hover{
      background-color: #fff;
      color: #000;
      border: 2px solid #000;
    }
  }

  .main-video{
    width: 100%;
    display: flex;

    &:after{
      content: '';
      display: table;
      padding-bottom: 56.25%;
    }

    iframe{
      width: 100%;
    }
  }
