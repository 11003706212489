@charset "UTF-8";
/* Cookie Dough */

.cookie {
  opacity: 0;
  position: fixed;
  transition: all .3s ease;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  font-size: .9em;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}


.cookie.is-active {
  z-index: 999999;
  opacity: 1;
  pointer-events: auto;
  display: flex;
}

.cookie-btn {
  appearance: none;
  border-radius: 4px;
  padding: 2px 6px;
  border: none;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  margin:0;
  margin-left: auto;
}

.cookie-btn:hover {
  background-color: #333333;
}

.cookie-box {
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  padding: 40px 40px 30px 40px;
}

.cookie-content {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 20px;
}

.cookie-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cookie-close:hover,
.cookie-close:focus {
  opacity: 1;
}
