@charset "UTF-8";
/* PROJEKT BOX and LIST */


.pbox {
  height: 100%;
  flex-basis: 100%;
  display: flex;
  position: relative;
  order: 20;
  @media (--ignt-mq-min-break-small) {
    flex-basis: 50%;
  }
  @media (--ignt-mq-min-break-medium) {
    flex-basis: 33.33%;
  }
  @media (--ignt-mq-min-break-large) {
    flex-basis: 25%;
  }
  @media (--ignt-mq-min-break-x-large) {
    flex-basis: 20%;
  }
  &:before {
    content:'';
    padding-bottom: 100%;
    display: block;
    width: 100%;
    transition: background-color .3s ease;
    pointer-events: none;
    mix-blend-mode: color;
  }
  &:hover {
    &:before{
      background-color: #000;
      z-index: 10;
    }

    .pbox-content{
      background-color: #000;
      *{
        color: #fff !important;
      }
    }
  }

  .headline{margin-bottom: 0}

  &.pbox--last{
    border: 5px solid #000;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding: 30px;
    font-family: var(--ignt-head-font-sans);
    font-size: 2.5em;
    word-break: break-word;
    transition: all ease .3s;

    &:before{
      display: none;
    }

    &:after{
      content: '';
      display: table;
      padding-bottom: 100%;
    }

    &:hover{
      color: #000;
      padding: 30px 20px 30px 40px;
    }
  }
}

.pbox-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    &--third {
      @media (--ignt-mq-min-break-medium) {
        & .pbox {flex-basis: 33.3%;}
      }
    }
    &--quarter {
      @media (--ignt-mq-min-break-medium) {
        & .pbox {flex-basis: 50%;}
      }
      @media (--ignt-mq-min-break-large) {
        & .pbox {flex-basis: 25%;}
      }
    }
    &--fifth {
      @media (--ignt-mq-min-break-large) {
        & .pbox {flex-basis: 20%;}
      }
    }
  }

.pbox-pic {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

.pbox-link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    position: absolute;
  }

.pbox-content {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .25);
    padding: 20px;
    background: white;
    margin: 20px;
    z-index: 10;
    transition: all ease .3s;

    *{
      transition: all ease .3s;
    }
  }

.pbox-subline{
  margin-top: 10px;
}

.pbox--top {
    order:10;
  }

.pbox-grid{
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 20px;


  @media (--ignt-mq-max-break-medium){
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  @media (--ignt-mq-max-break-small){
    grid-template-columns: minmax(0, 1fr);
  }
}
