@charset "UTF-8";
/* Projektspeicher CSS 2018 */

@import url('@viur/ignite/ignite.css');

@import url("foundation/appconf.css");
@import url("foundation/button.css");
@import url("foundation/form.css");
@import url("foundation/icon.css");
@import url("foundation/message.css");
@import url("foundation/wrap.css");

@import url("objects/topbar.css");
@import url("objects/keyvisual.css");
@import url("objects/event.css");
@import url("objects/projekt.css");
@import url("objects/footer.css");
@import url("objects/cookie.css");
@import url("objects/image.css");
@import url("objects/layout.css");
@import url("objects/room.css");

/*@import url("objects/popup/photoswipe.css");
@import url("objects/popup/modal.css");*/

@import url("fluidpage/fluidpage.css");

@font-face {
    font-family: 'Vieleck';
    src: url('../font-vieleck/vieleck-webfont.eot');
    src: url('../font-vieleck/vieleck-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font-vieleck/vieleck-webfont.woff') format('woff'),
         url('../font-vieleck/vieleck-webfont.ttf') format('truetype'),
         url('../font-vieleck/vieleck-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Vieleck';
    src: url('../font-vieleck/vieleckbold-webfont.eot');
    src: url('../font-vieleck/vieleckbold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../font-vieleck/vieleckbold-webfont.woff') format('woff'),
         url('../font-vieleck/vieleckbold-webfont.ttf') format('truetype'),
         url('../font-vieleck/vieleckbold-webfont.svg') format('svg');
    font-weight: bold;
    font-style: normal;
}
