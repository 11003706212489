@charset "UTF-8";
/* FOOTER */

.footer {
  display: flex;
  margin-top: auto;
  background-color: #000;
  color: #fff;
  padding: var(--ignt-outer-space);
  transform: skewY(-1deg) translateY(var(--ignt-footer-height));
  height: 100px;
  transform-origin: left;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  font-size: .9em;

  @media (--ignt-mq-min-break-medium){
    justify-content: flex-end;
  }

  z-index: 10;
  order: 10;
  position: relative;

  & a[href] {
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-edge {
  background-color: #000;
  height:var(--ignt-footer-height);
  width:100%;
  z-index: 0;
  order: 10;
}

.footer-address{
  display: flex;
  flex-direction: column;

  @media (--ignt-mq-min-break-medium){
    flex-direction: row;
  }
}

.footer-info{
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (--ignt-mq-min-break-medium){
    flex-direction: row;
    align-items: flex-start;
  }
}

.footer-item{
  display: flex;
  flex-direction: row;

  @media (--ignt-mq-min-break-medium){
    &:after{
      content: '\00a0|\00a0';
      display: flex;
    }

    .footer-info &{
      &:last-child{
         &:after{
           content: '';
         }
      }
    }

  }
}
