@charset "UTF-8";
/* BUTTONS */
.btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-flex;
    position: relative;
    border-width: 0;
    border-radius: 0;
    padding: 3px 11.25px;
    margin-top: 5px;
    margin-bottom: 5px;
    vertical-align: middle;
    font-size: 1.1em;
    line-height: 1.3;
    font-weight: 600;
    color: #f4f4f4;
    background-color: #000;
    box-shadow: none;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    transition: all 0.3s ease;

  &:hover, &:focus {
    color: #000000;
    background-color: #faf9f9;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
}

.btn--white {
  color: #000000;
  background-color: #fff;

  &:hover, &:focus {
    color: #fff;
    background-color: #000;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
}

.btn--center{
  justify-content: center;
  text-align: center;
}

.btn--inline{
  display: inline-flex;
  align-self: flex-start;
}
