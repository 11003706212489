@charset "UTF-8";
/* FLUIDPAGES */

@import url("fluidpage-framework.css");
@import url("fluidpage-element.css");
@import url("../fluidpage-project-conf.css");

.fluidContrastColor(@color){
  color: @color;
  .fluid-background-main & {
    color: contrast(var(--ignt-color-primary), @color);
  }
  .fluid-background-complement & {
    color: contrast(var(--ignt-color-secondary), @color);
  }
  .fluid-background-side & {
    color: contrast(@fluidColorSide, @color);
  }
  .fluid-background-white & {
    color: contrast(#fff, @color);
  }
}

