@charset "UTF-8";
/* FORM PARTS */

.input {
  padding: .3em .5em;
  border: 1px solid #000;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.label {
  display: none;
}
.select {
  padding: .3em .5em;
  border: 1px solid #000;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.form {
}
.textarea {
  padding: .3em .5em;
  border: 1px solid #000;
  min-width: 100%;
  flex: 1;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.switch {
}
.radio {
}
.check {
}
.option-group {
}
.input-group {
  align-items: initial;
  display: flex;
  flex-direction: column !important;
}

.input-group--block {
}

.input-group--bar {
}

.form-group{
  display: grid;
  grid-gap: 20px;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  align-items: flex-start;

  .ignt-bone{
    margin: 0 !important;
  }
}

.bone-descr{
  grid-column: span 2;
}

.bone-captcha{
  .input{
    min-width: 60px;
  }
}

.bone-captcha{
  .label{
    display: flex;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border: none;
    padding: 0 0 10px 0;
    font-size: .9em;
  }
}

form{
  .check{
    margin-top: 15px;
    margin-bottom: 15px;

    &-input:checked + &-label:before {
      background: #000;
      border: 2px solid #000;
    }

    .check-label{
      padding-left: 0;

      &:not(:empty):before {
        margin-right: 15px;
      }
    }

    .check-input:checked + .check-label:before{
      background: #000;
      border: 2px solid #000;
    }
  }
}

.bone-errors{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.bone-error{
  display: flex;
  font-size: .7em;
  font-weight: bold;
}
