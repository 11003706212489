@charset "UTF-8";
/* FLUIDPAGES Elements */

.msg{
  width: 100%;
  height: 100%;
}

.fluid-element {
  display: none;
  position: relative;
  height: auto;
  grid-column: span 1;
  background-size: cover;
  background-position: center;

    &.fluid-width-1{
    grid-column: span 1;
  }

  &.fluid-width-2{
    grid-column: span 2;
  }

  &.fluid-width-3{
    grid-column: span 3;
  }

  &.fluid-width-4{
    grid-column: span 4;
  }

  &.fluid-width-5{
    grid-column: span 5;
  }

  &.fluid-width-6{
    grid-column: span 6;
  }

  &.fluid-width-7{
    grid-column: span 7;
  }

  &.fluid-width-8{
    grid-column: span 8;
  }

  &.fluid-width-9{
    grid-column: span 9;
  }

  &.fluid-width-10{
    grid-column: span 10;
  }

  &.fluid-width-11{
    grid-column: span 11;
  }

  &.fluid-width-12{
    grid-column: span 12;
  }

  &.fluid-width-12{
    @media (--ignt-mq-max-break-medium){
        grid-column: span 12;
    }
  }

  &.fluid-width-fullwidth {
    grid-column: full;
    margin-bottom: var(--fluid-gap);
  }

  &.fluid-height-small {
    height: var(--fluid-height-small);
    overflow-y: hidden;
  }
  &.fluid-height-medium {
    height: var(--fluid-height-medium);
    overflow-y: hidden;
  }
  &.fluid-height-large {
    height: var(--fluid-height-large);
    overflow-y: hidden;
  }
  &.fluid-height-100 {
    height: 100vh;
    overflow-y: hidden;
  }

  &.fluid-height-1-1 {
    aspect-ratio: 1;
  }

  &.fluid-height-16-9 {
    aspect-ratio: 1.7777;
  }

  &.fluid-height-16-10 {
    aspect-ratio: 1.6;
  }

  &.fluid-height-4-3 {
    aspect-ratio: 1.3333;
  }

  &.fluid-background-main{
    background-color: var(--ignt-color-primary);
    color: #fff;
  }

  &.fluid-background-complement{
    background-color: var(--ignt-color-secondary);
    color: #fff;
  }

  &.fluid-background-white{
    background-color: #fff;
  }

  &.fluid-gap-top-small {
    padding-top: 10px;
  }

  &.fluid-gap-top-medium {
    padding-top: 20px;
  }

  &.fluid-gap-top-big {
    padding-top: 30px;
  }

  &.fluid-gap-bottom-small {
    padding-bottom: 10px;
  }

  &.fluid-gap-bottom-medium {
    padding-bottom: 20px;
  }

  &.fluid-gap-bottom-big {
    padding-bottom: 30px;
  }

  &.fluid-gap-right-small {
    padding-right: 10px;
  }

  &.fluid-gap-right-medium {
    padding-right: 20px;
  }

  &.fluid-gap-right-big {
    padding-right: 30px;
  }

  &.fluid-gap-left-small {
    padding-left: 10px;
  }

  &.fluid-gap-left-medium {
    padding-left: 20px;
  }

  &.fluid-gap-left-big {
    padding-left: 30px;
  }

  @media (--ignt-mq-max-break-small){
    &.is-mobile {
      display:flex;
      flex-basis: 100%;
    }
  }
  @media (--ignt-mq-min-break-small){
    @media (--ignt-mq-max-break-medium){
      &.is-tablet {
        display:flex;
        flex-basis: 50%;
      }
    }
  }
  @media (--ignt-mq-min-break-medium){
    &.is-desktop {
      display:flex;
    }
  }

  & img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center center;
    font-family: "object-fit: cover; object-position: center center;";
  }
  & img[data-sizes="auto"] {
    display: block;
  }
}

.fluid-picture {
  position: relative;
  width: 100%;
  flex-grow: 1;
  display: flex;
}

.fluid-picture-hoverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: fadeout(var(--ignt-color-secondary), 30%);
    color: contrast(var(--ignt-color-primary));
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all ease .3s;
    pointer-events: none;
    z-index:1;
    :hover > & {
      opacity: 1;
    }
    :not(.fluid-slider-image):not(.fluid-link) > & {
      margin: 0 var(--fluid-gap)/2;
    }
  }
.fluid-picture-contain {
    & img {
      object-fit: contain;
      object-position: center center;
      font-family: "object-fit: contain; object-position: center center;";
    }
  }
.fluid-picture-caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4.5em;
    font-family: var(--ignt-basic-font-head);
  }
.fluid-picture-content {
    .fluid-headline {
      font-size: 2.3em;
      letter-spacing: 1px;
      font-family: var(--ignt-basic-font-head);
    }
    .fluid-subline {
      font-size: 1.3em;
    }
    .fluid-descr {
      text-align: center;
      align-self: center;
    }
  }

.fluid-image-contain {
  & img {
    object-fit: contain;
    object-position: center center;
    font-family: "object-fit: contain; object-position: center center;";
  }
}

.fluid-image-cover {
  & img {
    object-fit: cover;
    object-position: center center;
    font-family: "object-fit: cover; object-position: center center;";
  }
}


@playIcon: 'data:image/svg+xml;utf8,<svg enable-background="new 0 0 512 512" height="512px" id="Layer_1" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient gradientTransform="matrix(1 0 0 -1 -41.66 349.04)" gradientUnits="userSpaceOnUse" id="SVGID_1_" x1="67.6434" x2="694.2763" y1="323.1215" y2="-303.5114"><stop offset="0" style="stop-color:#DD272D"/><stop offset="0.5153" style="stop-color:#CA2429"/><stop offset="1" style="stop-color:#B22025"/></linearGradient><path d="M391.939,159.642c-11.485-12.816-24.349-12.892-30.247-13.618  c-42.252-3.275-105.625-3.275-105.625-3.275h-0.142c0,0-63.374,0-105.616,3.275c-5.898,0.727-18.752,0.802-30.247,13.618  c-9.041,9.777-11.995,31.984-11.995,31.984s-3.02,26.057-3.02,52.115v24.424c0,26.076,3.02,52.124,3.02,52.124  s2.945,22.197,11.995,31.955c11.495,12.816,26.566,12.429,33.286,13.769C177.499,368.487,256,369.251,256,369.251  s63.44-0.113,105.691-3.35c5.898-0.755,18.762-0.83,30.247-13.647c9.041-9.758,11.995-31.955,11.995-31.955s3.02-26.057,3.02-52.124  V243.75c0-26.066-3.02-52.124-3.02-52.124S400.99,169.42,391.939,159.642z M218.297,312.626V199.375l94.376,56.626L218.297,312.626z  " fill="url(#SVGID_1_)"/></svg>';
.fluid-video {
  position: relative;
  width: 100%;
  flex-grow: 1;
  display: flex;

  .icon {
    display: block;
    width: 15%;
    height: auto;
    color: var(--ignt-color-secondary);
    transition: all ease .3s;
    z-index: 2;
  }
  .fluid-video-link:hover .icon {
    color: var(--ignt-color-primary);
  }
}

.fluid-video-contain {
    & img {
      object-fit: contain;
      object-position: center center;
      font-family: "object-fit: contain; object-position: center center;";
    }
  }

.fluid-video-hoverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: fadeout(var(--ignt-color-secondary), 30%);
    color: contrast(var(--ignt-color-primary));
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all ease .3s;
    z-index: 1;
    :hover > & {
      opacity: 1;
    }
    :not(.fluid-slider-image):not(.fluid-link) > & {
      margin: 0 var(--fluid-gap)/2;
    }
  }


.fluid-video-link,
.fluid-image-link {
  width: 100%;
  display: flex;
}

.fluid-slider-caption,
.fluid-image-cta {
  padding: var(--fluid-inner)/2 var(--fluid-inner);
  display: flex;
  flex-direction: column;
  background-color: rgba(214, 0, 22, 0.8);
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #fff;
}

.fluid-link {
  position: relative;
  a& {
    color: inherit;
    text-decoration: none;
  }
}

.fluid-quote {
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

.fluid-quote-link {
    padding: var(--fluid-inner) 0;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: center;
  }

.fluid-quote-cta {
    align-self: center;
    margin-top: var(--fluid-inner);
  }

.fluid-quote-headline {
    font-size: 3.5em;
    letter-spacing: 1px;
    font-family: var(--ignt-basic-font-head);
    width: 100%;
  }

.fluid-quote-subline {
    font-size: 1.3em;
  }

.fluid-quote-descr {
    text-align: center;
    align-self: center;
  }


.fluid-text {
  flex-direction: column;
}

.fluid-text-headline {
    font-weight: 700;
  }
.fluid-text-subline {
    font-weight: 700;
  }
.fluid-text-descr {
    max-width: 75ch;
  }
.fluid-text-link {
    margin-top: var(--fluid-inner);
  }


.fluid-descr {
  & h1,
  & h2,
  & h3,
  & h4 {
    font-weight: 700;
  }
  ul {
    padding: 3px 18px;
    margin: 0;
    list-style-type: disc;
    text-align: left;
    display: inline-block;
  }
}

.fluid-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.fluid-card-box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
.fluid-card-link {
    display: flex;
    //flex-wrap: wrap;
    flex-direction: column;
    flex-basis: 100%;
  }
.fluid-card-picture {
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 20px;

    .fluid-height-small & {
      flex-basis: var(--fluid-height-small);
    }
    .fluid-height-medium & {
      flex-basis: var(--fluid-height-medium);
    }
    .fluid-height-large & {
      flex-basis: var(--fluid-height-large);
    }
    .fluid-height-100 & {
      flex-basis: 100vh;
    }
  }
.fluid-card-cta {
    margin-top: 1em;
    margin-bottom: 0;
    font-weight: .97em;

    :after{
      margin-left: .6em;
      content: '>';
      display: inline-block;
    }

    &:hover{
      color: var(--ignt-color-primary);
    }
  }
.fluid-card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
.fluid-card-headline {
    font-weight: 700;
  }
.fluid-card-subline {
    font-weight: 700;
  }


.fluid-align-left {
      .fluid-headline,
      .fluid-subline,
      .fluid-descr {
        text-align: left;
      }

  }
.fluid-align-right {
      .fluid-headline,
      .fluid-subline,
      .fluid-descr {
        text-align: right;
    }
  }
.fluid-align-center {
      .fluid-headline,
      .fluid-subline,
      .fluid-descr {
        text-align: center;
        align-self: center;
      }
  }
.fluid-align-justify {
     .fluid-headline,
      .fluid-subline,
      .fluid-descr {
        text-align: justify;
      }
  }



.btn {
  .fluid-background-main & {
  }
  .fluid-background-complement & {
  }
}

.fluid-descr {
  & a {
    color: var(--ignt-color-text-default);
    border-bottom: 1px solid var(--ignt-color-text-default);

    &:hover{
      color: var(--ignt-color-primary);
      border-bottom: 2px solid var(--ignt-color-primary);
    }
  }
  & h1 {
    color: var(--ignt-color-primary);
    font-family: var(--ignt-head-font-sans);
    font-weight: 700;
    font-size: 3.2em;
  }
  & h2 {
    color: var(--ignt-color-primary);
    font-family: var(--ignt-head-font-sans);
    font-weight: 700;
    font-size: 2.4em;
  }
  & h3 {
    font-family: var(--ignt-head-font-sans);
    font-weight: 700;
    font-size: 1.5em;
  }
}


.fluid-headline{
  color: var(--ignt-color-primary);
  line-height: 1.3;
  margin-bottom: .3em;
  font-weight: 700;
  width: 100%;
}

h1.fluid-headline {
  font-size: 3.2em;
}
h2.fluid-headline {
  font-size: 2.4em;
}
h3.fluid-headline {
  font-size: 1.5em;
}

.fluid-subline{
  line-height: 1.3;
  margin-bottom: .5em;
  font-size: 1.2em;
  width: 100%;
}

.fluid-align-center{
  .fluid-text-link{
    text-align: center;
  }
}

.fluid-top-gap-small,
.fluid-top-gap-large,
.fluid-bottom-gap-small,
.fluid-bottom-gap-large,
.fluid-left-gap-small,
.fluid-left-gap-large,
.fluid-right-gap-small,
.fluid-right-gap-large{
  @media (--ignt-mq-max-break-small){
    margin-left: 0 !important;
    margin-right: 0!important;
    padding-left: 0 !important;
    padding-right: 0!important;
  }
}


.fluid-descr{
  max-width: 100ch;
  width: 100%;
  line-height: 1.6;
}

.fluid-facts{
  flex-direction: column;
  background-color: #000;
  color: #fff;
  padding: 40px 30px 30px 30px;

  .pbox-headline{
    margin-bottom: 10px;
  }

  .btn{
    margin-top: auto;
  }

  @media (--ignt-mq-min-break-x-large){
    font-size: .9em;
  }
}
