@charset "UTF-8";

:root,
:host {
  --fluid-page-width: var(--ignt-page-width-default);

  --fluid-inner: 20px;
  --fluid-outer: var(--ignt-outer-space);
  --fluid-gap: 20px;

  --fluid-height-small: 200px;
  --fluid-height-medium: 350px;
  --fluid-height-large: 600px;

}
