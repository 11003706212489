@charset "UTF-8";
/* KEY VISUAL */

.kiwi {
  width: 100%;
  height: 80vh;
  display: flex;
  position: relative;
  z-index: 0;
}
.kiwi-pic {
  width: 100%;
  position: absolute;
  height: 100%;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
.kiwi-box {
}
.kiwi-cut {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  transform: skewY(1deg) translateY(50px);
  transform-origin: right;
  margin-top: -50px
}
.kiwi--view {
  height: 60vh;

  img{
    width: 100%;
    object-fit: cover;
  }
}




