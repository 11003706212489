@charset "UTF-8";

*{
  font-variant-ligatures: no-common-ligatures;
}

html{
  overflow-y: auto;
  scroll-behavior: smooth;
}

p{
  line-height: 160%;
}

.outerwrap {
  display:flex;
  flex-wrap:nowrap;
  min-height: 100vh;
  flex-direction: column;
}

.mainwrap {
  display:flex;
  flex-wrap:wrap;
  width:100%;
  flex-grow: 1;
  margin-top: 10px;
  justify-content: flex-start;

  .ebox--subevent:first-child {
    margin-top: 50px;
  }
}

.section {
  &:last-child {
    padding-bottom: 50px;
  }
}

.sidebar {
  margin-left: var(--ignt-outer-space);
  * + .sidebar-group {
    margin-top: 30px;
    padding-top: @innerSpace;
  }
}

.headline {
  font-family: var(--ignt-head-font-sans);
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 20px;
  word-break: break-word;

  h1&{
    font-size: 3.5em;
    margin-top: 70px;
  }

  h2&{
    font-size: 2.5em;
  }

  h3&{
    font-size: 2em;
  }

  h4&{
    font-size: 1.3em;
  }
}

.subline {
  font-weight: 700;
  font-size: 1.5em;
  margin-bottom: @innerSpace;
}

.topbar-menu{
  display: flex;
  flex-direction: row;
  margin-left: 15px;

  @media (--ignt-mq-max-break-small){
    font-size: .95em;
  }
}

.topbar-menu-item{
  padding: 10px;
  border-bottom: 2px solid transparent;

  &:hover{
    color: #000;
    border-bottom: 2px solid #000;
  }
}

.topbar-cta{
  display: flex;
  align-items: center;
  margin-left: 10px;

  .icon{
    display: none;
    margin-right: 7px !important;
    margin-bottom: 1px;
  }

  strong{
    display: flex;
    align-items: center;
    margin-top: -.1em;
  }

  @media (--ignt-mq-max-break-small){
    strong{
      display: none;
    }

    .icon{
      display: flex;
      margin-right: -2px !important;
      margin-left: -2px !important;
    }
  }
}

.topbar-logo{
  margin-right: auto;

  @media (--ignt-mq-max-break-small){
    max-width: 60px;
  }

  img{
    width: 100px;
  }
}

.linkbox{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background-color: #000;
  color: #fff !important;
  width: 100%;
  word-break: break-word5;
  transition: all ease .3s;
  border: 2px solid #000;
  margin-top: 10px;

  a&:hover{
    border: 2px solid #000;
    background-color: #fff;
    color: #000 !important;

    &:after{
      padding-left: 3px;
    }
  }

  a&:after {
    content: '\276F';
    display: inline-block;
    margin-left: auto;
    padding-left: 0;
    transition: padding-left ease .3s;
  }
}

.main-image{
  flex: 1;
  min-height: 90vh;
  position: relative;
  overflow: hidden;
  svg{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 90vh;
  }
}

svg{
  *{
    transition: all ease .5s;
  }

  #apfelblatt, #frucht{
    fill: #fff !important;
  }

  #apfel:hover{
      #apfelblatt{
        fill: #40991e !important;
      }

      #frucht{
        fill: #e20000 !important;
      }
    }

  @keyframes blatt {
    from {transform: rotate(0deg);}
    to {transform: rotate(9deg);}
  }

  @keyframes bluete {
    from {transform: rotate(0deg);}
    to {transform: rotate(9deg);}
  }


  #blume{
    #topf1, #topf2, #pollen, #bluete, {
      fill: #fff;
      transform-box: fill-box;
      transform-origin: center;
    }

    #blatt1, #blatt2{
      transform-box: fill-box;
      transform-origin: bottom;
    }

    &:hover{
      #topf1, #topf2{
        fill: #83551d;
      }

      #bluete{
        fill: #f5969b;
        animation: bluete 1.5s ease 0s infinite alternate;
      }

      #pollen{
        fill: #edc244;
      }

      #blatt1, #blatt2{
        fill: #40991e !important;
        animation: blatt 1.5s ease 0s infinite alternate;
      }
    }
  }


  #mausbutton{
    #mbutton{
      transform-box: fill-box;
      transform-origin: center;
    }

    &:hover{
      #maus{
        transform: translate(0px, -1%);
      }
      #mbutton{
        transform: scale(.95);

        polyline{
          fill: #eeeeee !important;
        }
      }
    }
  }


  #briefklappe{
    transform-box: fill-box;
    transform-origin: top center;
    fill: #fff !important;
  }

  #brief{
    rect{
      fill: #fff !important;
    }

    &:hover{
      #briefklappe{
        transform: scale(-1);
      }
    }
  }

  #auge, #pupille{
    transform-box: fill-box;
    transform-origin: center;
  }

  #kopf{
    #kopf-bg-1, #kopf-bg-2, #kopf-bg-3{
      fill: #fff;
    }

    #augenbraue-links, #augenbraue-rechts, #mund{
      transform-box: fill-box;
      transform-origin: center;
    }

    &:hover{
      #augenbraue-links{
        transform: translate(0, -1px);
      }

      #augenbraue-rechts{
        transform: translate(0, -1px);
      }

      #mund{
        transform: rotate(10deg);
      }
    }
  }

  #birne{
    fill: #fff;
  }

  @keyframes strahl {
    from {transform: scale(1)}
    to {transform: scale(.8)}
  }

  #gluehbrine{
    #strahl1, #strahl2, #strahl3, #strahl4 {
      transform-box: fill-box;
      transform-origin: center;
    }

    &:hover{
      #birne{
        fill: #ffd93e;
      }

      #strahl1{animation: strahl 1.5s linear infinite alternate;}
      #strahl2{animation: strahl 1.5s linear infinite alternate;}
      #strahl3{animation: strahl 1.5s linear infinite alternate;}
      #strahl4{animation: strahl 1.5s linear infinite alternate;}
    }
  }

  #spiegelbild, #bilderrahmen{
    fill: #ffffff;
  }

  #spiegel{
    &:hover{
      #spiegelbild{
        fill: #c7f5ff;
      }
      #bilderrahmen{
        fill: #ffa42d;
      }
    }
  }

  #ladebalken{
    transform-box: fill-box;
    transform-origin: left;
    transform: scaleX(0);
    fill: #d6281f !important;
  }

  #monitor{
    #bildschirm{
        fill: #fff;
      }

    &:hover{
      #ladebalken{
        transform: scaleX(1);
        transition: all ease 3s;
        stroke: none !important;
      }

      #bildschirm{
        fill: #c7f5ff;
      }
    }
  }

  @keyframes pulse {
    from {transform: scaleX(1)}
    to {transform: scaleX(.75)}
  }

  #sprechblase{

    polygon{
      fill: #fff !important;
    }

    *{
      transform-box: fill-box;
      transform-origin: left;
    }

    &:hover{
      polygon{
        fill: #eeeeee !important;
      }

      #zeile1{animation: pulse 2s linear 0s infinite alternate;}
      #zeile2{animation: pulse 3.5s linear .2s infinite alternate;}
      #zeile3{animation: pulse 2.5s linear .1s infinite alternate;}
      #zeile4{animation: pulse 3s linear .2s infinite alternate;}
    }
  }

  #lampe{
    #lampe1, #lampe2, #lampe3, #lampeschein, #lampeschalter{
      fill: #fff;
    }

    &:hover{
      #lampe1, #lampe2, #lampe3{
        fill: #5bd192;
      }

      #lampeschein{
        fill: #f5c138;
      }

      #lampeschalter{
        fill: #9c0019;
        transform: translate(0, 1px);
      }
    }
  }

  #kamera{
    #blitz1, #blitz2, #blitz3, #blitzlicht, #linse, #kamerabody, #knopf{
      fill: #fff;
    }

    &:hover{
      #blitz1, #blitz2, #blitz3, #kamerabody{
        fill: #8b8b8b;
      }

      #linse{
        fill: #c7f5ff;
      }

      #knopf{
        fill: #9c0019;
        transform: translate(0, 1px);
      }
    }
  }

   @keyframes wobble {
    0% {transform: rotate(0deg);}
    33% {transform: rotate(1deg);}
     66% {transform: rotate(0deg);}
     100% {transform: rotate(-1deg);}
  }

  #bohrmaschine{
    transform-box: fill-box;
    transform-origin: center;

    #koerper, #bohrkopf, #spitze, #akku{
      fill: #fff;
    }

    &:hover{
      animation: wobble .3s linear infinite alternate;

      #koerper, #akku{
        fill: #008915;
      }

      #bohrkopf{
        fill: #7f7f7f;
      }

      #spitze{
        fill: #c0c0c0;
      }
    }
  }

  @keyframes dampf {
    from {transform: translate(0, 0); opacity: 1}
    to {transform: translate(0, -5px); opacity: 0}
  }

  #tasse{
    #tassebauch, #tassehenkel, #tasseloch{
      fill: #fff;
    }

    #dampf1, #dampf2, #dampf3{
      transform-box: fill-box;
      transform-origin: center;
    }

    &:hover{
      #tassebauch, #tassehenkel{
        fill: #ea3d53;
      }

      #tasseloch{
        fill: #fff;
      }

      #dampf1{animation: dampf 2s linear 0s infinite ;}
      #dampf2{animation: dampf 3.5s linear .2s infinite ;}
      #dampf3{animation: dampf 2.5s linear .1s infinite ;}
    }
  }

   @keyframes takt {
    0% {transform: rotate(-30deg);}
    50% {transform: rotate(30deg);}
     100% {transform: rotate(-30deg);}
  }

  #metronom{
    #metronomkoerper{
      fill: #fff;
    }

    #metronomarm{
      transform-box: fill-box;
      transform-origin: bottom center;
      transform: rotate(-30deg);
    }

    &:hover{
      #metronomkoerper{
        fill: #ac7242;
      }

      #metronomarm{
        animation: takt 1s linear infinite;
      }
    }
  }

  @keyframes note {
    0% {transform: rotate(0deg);}
    25% {transform: rotate(10deg);}
     50% {transform: rotate(0deg);}
     75% {transform: rotate(-10deg);}
    100% {transform: rotate(0deg);}
  }

  #gitarre{
    #koerper1, #koerper2, #koerper3, #loch{
      fill: #fff;
    }

    #note1, #note2, #note3 {
      transform-box: fill-box;
      transform-origin: center;
    }

    &:hover{
      #koerper1, #koerper2, #koerper3 {
        fill: #ac7242;
      }

      #loch{
        fill: #4c3020;
      }

      #note1{animation: note 2s linear 0s infinite ;}
      #note2{animation: note 3.5s linear .2s infinite ;}
      #note3{animation: note 2.5s linear .1s infinite ;}
    }
  }

  #massband{
    #huelle, #huelle1, #band {
      transform-box: fill-box;
      transform-origin: left;
      fill: #fff;
    }

    &:hover{
      #huelle{
        fill: #5771bf;
      }

      #huelle1{
        fill: #c0c0c0;
      }

      #band{
        transform: scaleX(.9);
      }
    }
  }

  #bier{
    #inhalt, #glass, #glas1{
      fill: #fff;
    }

    &:hover{
      #inhalt{
        fill: #ffc039;
      }

      #blase1{animation: dampf 2s linear 0s infinite ;}
      #blase2{animation: dampf 3.5s linear .2s infinite ;}
      #blase3{animation: dampf 2.5s linear .1s infinite ;}
      #blase4{animation: dampf 3.5s linear .2s infinite ;}
    }
  }

  #biene{
    transform-box: fill-box;
    transform-origin: center;

    #bienenrumpf, #fluegel1, #fluegel2{
      fill: #fff;
    }

    &:hover{
      animation: note 2s linear 0s infinite ;
      #bienenrumpf{
        fill: #dd9c12;
      }

      #fluegel1, #fluegel2{
        fill: #c7f5ff;
      }
    }
  }

  #palette{
    #farbe1, #farbe2, #farbe3, #farbe4, #holz{
      fill: #fff;
    }

    &:hover{
      #holz{
        fill: #ac7242;
      }
      #farbe1{
        fill: #5771bf;
      }
      #farbe2{
        fill: #40991e;
      }
      #farbe3{
        fill: #edc244;
      }
      #farbe4{
        fill: #bf0700;
      }
    }
  }

  #tastatur{
    #tastatur-bg{
      fill: #fff;
    }

    *:not(#tastatur-bg){
      transform-box: fill-box;
      transform-origin: center;
      fill: #fff;

      &:hover{
        transform: scale(.9);
      }
    }

    &:hover{
      #tastatur-bg{
        fill: #eeeeee;
      }
    }
  }
}

.img-item{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.img-item-img{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7em;
  height: 7em;
  min-width: 7em;
  min-height: 7em;
  margin-right: 20px;
}

.livestream{
  width: 100%;
  aspect-ratio: 16 / 9;
  margin: 60px 0 30px 0;

  iframe{
    width: 100%;
    height: 100%;
  }
}

.live-stream-headline{
  color: #fff;
  font-weight: 600;
  font-size: 2em;
  margin-bottom: 30px;
}
