@charset "UTF-8";
/* EVENT BOX */

@elistSpace: 20vh;

.event-similar {
    margin-top: 40px;
  }

.event-sponsors{
    margin-top: 40px;
  }

.elist-year {
    border-bottom: 2px dashed black;
    z-index: 20;
  }

.elist-year-badge {
    position: absolute;
    left: 50%;
    padding: 5px 15px;
    background-color: #fff;
    transform: translate(-50%, -50%) rotate(4deg);
    font-family: 'Vieleck', sans-serif;
    font-weight: 600;
    font-size: 1.5em;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .25);
  }

.elist-archive {
    min-height: @elistSpace;
    align-items: center;
    display: flex;
    margin-top: var(--sl-spacing-medium);
  }


.ebox {
  display: flex;
  flex-direction: row;
  padding: 20px;

  &:hover{
    color: #000;
  }
}

.ebox-wrap--topevent {
    height: @elistSpace*3;
    display: flex;
    position: relative;
  }
.ebox-wrap:nth-of-type(odd) {
    background-color: #f4f4f4;
  }

.ebox-headline{
    font-weight: bold;
    line-height: 1.3;
    font-size: 1.4em;
    margin-bottom: 10px;
    word-break: break-word;
  }

.ebox-image {
    height: 100px;
    width: 100px;
    margin-left: 20px;
    object-fit: cover;
    object-position: center center;
    aspect-ratio: 1;

    @media (--ignt-mq-min-break-medium) {
      height: 200px;
      width: 200px;
    }
  }

.ebox-content {flex-basis: 100%;}

.ebox-date {
    font-size: .8em;

    span{
      display: inline-block;
      margin-bottom: 5px;
    }
  }

.ebox-btn {
    height: 100px !important;
    width: 100px !important;
    min-height: 100px !important;
    min-width: 100px !important;
    object-fit: cover;
    object-position: center center;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (--ignt-mq-min-break-medium) {
      min-height: 200px !important;
      min-width: 200px !important;
      height: 200px !important;
      width: 200px !important;
    }

    & span {
      display: none;
      @media (--ignt-mq-min-break-medium) {
        display: block;
      }
    }
  }

  .ebox--subevent .ebox {
    display: flex;
    width: 100%;
  }

  .ebox--note .ebox-btn,
  .ebox--blog .ebox-btn  {
    display: flex;
    width: 100%;
    min-height: @elistSpace/2;

  }

  .ebox--blog {
    background-color: #000 !important;
    padding: 30px 0;

    & *,
    & a {
      color: #fff;
    }
  }

.ebox--topevent .ebox {
    display: block;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    padding: 20px;
    background-color: white;
    width: 30vw;
    min-width: 300px;
    min-height: 150px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.ebox-wrap.ebox--topevent {
    height: 60vh;
    display: flex;
    position: relative;
}

.box{
  display: block;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  padding: 20px;
  background-color: white;
  width: 30vw;
  min-width: 300px;
  min-height: 150px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, .25);
}

.event-date{
  background-color: #000;
  color: #fff;
  font-size: 1.7em;
  font-family: var(--ignt-head-font-sans);
  padding: 20px;
}

.event-gallery{
  display: grid;
  width: 100%;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 20px;

  @media (--ignt-mq-max-break-large){
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  @media (--ignt-mq-max-break-medium){
    grid-template-columns: minmax(0, 1fr);
  }
}

.event-gallery-image{
  height: 100%;
  object-fit: cover;
}

.event-teaser-image{
  width: 100%;
}

.sponsors-list{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
}

.sponsors-link{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  max-width: 125px;
  margin: 0 25px 10px 0;

  img{
    display: flex;
    height: 100%;
    width: auto;
  }
}

.tag-group{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.tag{
  border: 1px solid var(--ignt-color-primary);
  color: var(--ignt-color-primary);
  padding: .4em 1em;
  border-radius: 2em;
  transition: all ease .3s;
  white-space: nowrap;

  &:hover{
    background-color: var(--ignt-color-primary);
    color: #fff;
   }
}
