@charset "UTF-8";
/* PROJEKT BOX and LIST */

.room {
  position: fixed;
  top: 120px;
  right: -20px;
  background-color: #000;
  color: #fff;
  padding-right: 110px;
  z-index: ~"1";

  @media (--ignt-mq-max-break-medium){
    padding-right: 20px;
  }

  &-list {
    margin-bottom: @innerSpace;

    @media (--ignt-mq-max-break-medium){
      display: none;
    }
  }
}

.room-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0;

  .icon{
    margin-right: 10px;
  }
}
