@charset "UTF-8";
/* FLUIDPAGES FRAMEWORK */

.fluid-wrap {
  display: grid;
  grid-template-columns: [full-start] minmax(20px, 1fr) [main-start] minmax(0, 1200px) [main-end] minmax(20px, 1fr) [full-end];
  margin: 20px 0;
}

.fluid-grid {
  display: grid;
  grid-column: main;
  grid-template-columns: repeat(12, minmax(0, 100px) );
  grid-gap: 30px;

  @media print{
    padding: 0;
    margin: 0;
    display: block;
    flex: none;
    max-width: 210mm;
  }
}


