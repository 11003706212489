@charset "UTF-8";
/* Vi Icon */

.i {
  height: 2em;
  width: 2em;
  line-height: 2;
  background-color: #008089;
  border-radius: .4em;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg,
  & img {
    width: 75%;
    height: 75%;
    border-radius: .4em;
  }
}

.i--small {
  height: 1.6em;
  width: 1.6em;
  & svg,
  & img {
    width: 90%;
    height: 90%;
  }
}

.icon {
  display: inline-block;
  vertical-align: text-top;
  fill: currentcolor;
  color: inherit;
  width: 1em;
  height: 1em;

  & path,
  & circle,
  & rect,
  & ellipse,
  & polygon,
  & polyline {
    fill: inherit;
    stroke: none;
  }
}
