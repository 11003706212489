@charset "UTF-8";
/* MESSAGES and ALERTS */

.msg {
  display: none;
  opacity: 0;
  transition: opacity .5s ease;
  &.is-active {
    display: block;
    opacity: 1;
  }
}

.msg--success {
}

.msg--error {
}
